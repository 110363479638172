import React from 'react'
import Layout from '../../components/Layout'
import SEO from '../../components/seo'
import PodcastCite from '../../components/Tools/PodcastCite'
import FAQ from '../../components/FAQ'

class Page extends React.Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <SEO
          title="How to cite a podcast in Harvard, MLA, APA & Chicago"
          description="Podcast reference tool & definitive guide to citing Podcats in your paper for Harvard, MLA, APA & Chicago styles"
        />

        <article>
          <h1>How to cite a podcast</h1>
          <div>
            <p>
              Podcasts have rapidly grown in popularity over the last few years. They are widely considered a credible
              source of information. In many ways, they're not too dissimilar from online news articles or magazines.
              There are still authority sources providing information and critical points, the difference is that the
              words are now spoken rather than written down.
            </p>

            <PodcastCite />

            <h2 id="essentialpodcastcitinginformation">Podcast citing information</h2>
            <p>Regardless of what citing style you need to use, you will need;</p>
            <ol>
              <li>
                <h3>The full name of whoever created and posted the content</h3>
                <p>
                  This can be the name of whoever is narrating it, the name of the company that made it, or even just
                  someone's username. This can easily be found when you listen to the podcast as they all need to be
                  posted by someone.
                </p>
              </li>
              <li>
                <h3>Podcast Title</h3>
                <p>Self-evident. Remember, we're talking about the title of the actual podcast itself.</p>
              </li>
              <li>
                <h3>Episode title</h3>
                <p>If your podcast is one of many from a series, then you need to have the correct episode title.</p>
              </li>
              <li>
                <h3>Publisher</h3>
                <p>
                  Where you found the content and where it was published for people to consume. This could be iTunes, Apple Podcasts, Google Podcasts,
                  Spotify, or it may even be YouTube if you watched the podcast.
                </p>
              </li>
              <li>
                <h3>Published Date</h3>
                <p>
                  The exact date of when the podcast was initially posted. This is not the same as the date you
                  listened/watched it! However, you should also make a note of that as well.
                </p>
              </li>
              <li>
                <h3>Podcast link</h3>
                <p>Exact URL of the podcast to use as a link.</p>
              </li>
            </ol>
            <h2 id="howtociteapodcastinharvard">How to cite a podcast in Harvard</h2>
            <ol>
              <li>State the Author/Presenter of the podcast or the Company Name</li>
              <li>Put the Year Published (put this in round brackets)</li>
              <li>
                State the Episode Title - this might just be a number, but put it in italics, and
                followed by [podcast]
              </li>
              <li>Add the Podcast Title</li>
              <li>Podcast URL</li>
              <li>Access Date</li>
            </ol>
            <p>Here's an example:</p>

              <code>
                  Carlin, D. (2021). <em>Supernova in the East II</em>. [podcast] Hardcore History. Available at:
                  https://itunes.apple.com/ca/podcast/dan-carlins-hardcore-history/id173001861?mt=2 [Accessed 30 Jan. 2021].
              </code>

            <p>
              For the in-text citation, you just need the name of the podcast author and the date it was published. So,
              for the above example, it would merely be (Carlin, 2021).
            </p>
            <h2 id="howtociteapodcastinmla8thedition">How to cite a podcast in MLA 8th edition</h2>
            <p>
              For the full MLA referencing style, things are slightly different. If this is the method you use, then take a
              look at the info below:
            </p>
            <ol>
              <li>
                To start, you need the Last Name, First Name of the podcast creator (if there's no name given, then put
                the username or name of the company)
              </li>
              <li>Next, you have the "Title Of The Podcast Episode" - make sure you put it in quotation marks</li>
              <li>Then, put Title Of The Podcast in italics</li>
              <li>After that, you have to put the name of where the podcast was published</li>
              <li>The penultimate step is putting in the Date The Podcast Was Posted</li>
              <li>Lastly, stick the URL in there</li>
            </ol>
            <p>
              Here's an example of podcast citing in MLA (we'll be using the same podcast for all examples, just to make
              life easier):
            </p>
            <code>
              Carlin, Dan. "Supernova in The East II". <em>Hardcore History</em>, iTunes, 12 Jan. 2021.
              https://itunes.apple.com/ca/podcast/dan-carlins-hardcore-history/id173001861?mt=2. Accessed 30 Jan 2021.
            </code>
            <p>
              For the in-text citation, it's similar to Harvard, but you don't need to put the year in. So, it would
              just be (Carlin).
            </p>
            <h2 id="howtociteapodcastinapa">How to cite a podcast in APA</h2>
            <p>
              APA citation is much more straightforward and easier to follow. If you
              need to use this when citing a podcast in your paper, then user our apa citation generator or follow the
              following structure and examples:
            </p>
            <ol>
              <li>
                Start with the name of the podcast creator/author in this format: Last Name, First Name Initial, Middle
                Name Initial. (Put in rounded brackets their title, e.g. author)
              </li>
              <li>Then, display the Date Published in this structure: Year, Month Date.</li>
              <li>Next, you need to put the Podcast Episode Title in italics</li>
              <li>Follow this by putting [Audio podcast] to show what your source actually is</li>
              <li>Lastly, put Retrieved From, following by the URL</li>
            </ol>
            <p>Here's your example:</p>
            <code>
              Carlin, D (Host). (2021, January 12)
              <em>Supernova in the East II</em> [Audio podcast]. Retrieved from
              https://itunes.apple.com/ca/podcast/dan-carlins-hardcore-history/id173001861?mt=2
            </code>
            <p>When citing this in-text, it's the exact same format as Harvard referencing; (Carlin, 2021).</p>
            <h2 id="howtociteapodcastinchicago">How to cite a podcast in Chicago Format</h2>
            <p>
              The Chicago style format is currently in its 17th edition. For anyone that uses this particular style,
              follow this structure or use our chicago citation generator:
            </p>
            <ol>
              <li>
                Begin with the podcast creators Last Name, First Name - if it's a company or username, then just use
                that instead
              </li>
              <li>Put the "Episode Title" in quotation marks</li>
              <li>Put the Podcast Title in italics</li>
              <li>After this, make sure you put Podcast audio before the next step</li>
              <li>From here, you input the Month Date, Year of Publication</li>
              <li>Finally, you finish things off with the URL</li>
            </ol>
            <p>So, using our previous example, a Chicago-style citation will look like this:</p>
            <code>
              Carlin, Dan. "Supernova in the East II". <em>Hardcore History</em>. Podcast Audio. January 12, 2021.
              https://itunes.apple.com/ca/podcast/dan-carlins-hardcore-history/id173001861?mt=2
            </code>
            <p>When you cite this in-text, you simply put the author and date, with no comma; (Carlin 2021).</p>
            <h2 id="summary">Summary</h2>
            <p>
              To end this article, we'll briefly summarize everything so you know exactly how to cite a podcast
              depending on your referencing style.
            </p>
            <h5 id="harvardreferencingstructure">Harvard Referencing Structure:</h5>
            <code>
              Last Name, First Initial. (Year Published).
              <em>Podcast Episode Title</em>. [podcast] Title Of Podcast. Available at: URL [Date Accessed]
            </code>
            <h5 id="mla8theditioncitationstructure">MLA 8th Edition Citation Structure:</h5>
            <code>
              Last Name, First Name. "Podcast Episode Title". <em>Podcast Title</em>, Publisher Name/Where You Listened
              To It, Date Podcast Was Posted, URL.
            </code>
            <h5 id="apacitationstructure">APA Citation Structure:</h5>
            <code>
              Last Name, First Initial. Middle Initial(s). (Podcast Contributors Title). (Year, Month Date).{' '}
              <em>Episode Title</em> [Audio Podcast] Retrieved from URL.
            </code>
            <h5 id="chicagoreferencingstructure">Chicago Referencing Structure:</h5>
            <code>
              Last Name, First Name. "Title Of Podcast Episode".
              <em>Title Of Podcast</em>. Podcast Audio, Month Date, Year Published. URL
            </code>
            <p>
              There you have it, you should be able to cite a podcast in any of these styles, and feel free to refer
              back to the examples laid out earlier on. Remember, it's essential that you learn how to cite things
              correctly when writing a paper. If this is an academic paper that's being marked, then you could easily
              lose points by getting it wrong. Ensure you know how to reference in-text and at the end of the paper in a
              bibliography.
            </p>
            <p>
              If you want to make your life a lot easier, then you can use our podcast cite tool that lets you put in
              all the information and generates the perfect reference for you. Just make sure you select the correct
              referencing style when using tools like this!
            </p>
          </div>

          <h2>Podcast Citation FAQ</h2>
          <FAQ
            items={[
              {
                question: 'Should I include the podcast episode number?',
                answer:
                  'Yes, Ensure you include the episode number with the podcast title, you can use our generator to help you format correctly.',
              },
              {
                question: 'Can I cite a podcast?',
                answer: 'Podcasts are often cited, Just make sure you use the correct style.',
              },
              {
                question: 'How do I cite a podcast in Harvard?',
                answer: 'Last Name, First Initial. (Year Published). <em>Podcast Episode Title</em>. [podcast] Title Of Podcast. Available at: URL [Date Accessed]',
              },
              {
                question: 'Should I include the Podcast Sponsor in the citation?',
                answer: 'Yes you can include funding such as sponsors, however its not required for full references.',
              },
              {
                question: 'How to cite a podcast on cv?',
                answer: 'Last Name First Initial. <em>Your role</em>. “Title of the segment.” Title of the Audio Publication. Date of release. URL',
              },
            ]}
          />
        </article>
      </Layout>
    )
  }
}

export default Page
