import React from 'react'
import styles from './index.module.scss'
import { Link } from 'gatsby'


class PodcastCite extends React.Component {


  constructor(props) {
    super(props)
    this.state = {
      firstName: null,
      lastName: null,
      podcastTitle: null,
      episodeTitle: null,
      publisher: null,
      dateAccessed: null,
      dateReleased: null,
      link: null,
    }

    this.handleInputChange = this.handleInputChange.bind(this)
  }

  handleInputChange(event) {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name

    this.setState({
      [name]: value,
    })
  }

  render() {
    const firstInitial = this.state.firstName ? this.state.firstName.split('')[0] : ''
    return (
      <div>

        <div className={styles.backContainer}>
      <div className={styles.backgroundCard}></div>
        <div className={styles.toolCard}>

        <div>
        <h2>Podcast Citation Generator Tool</h2>

        <h3>Whats the name of the podcast host?</h3>

        <input type="text" name='firstName' placeholder='First Name' onChange={this.handleInputChange}/>
        <input type="text" name='lastName' placeholder='Last Name' onChange={this.handleInputChange}/>


        <span>
                    <h3>Whats the name of the podcast & episode?</h3>
                    <input type="text" name='podcastTitle' placeholder='Podcast Title'
                           onChange={this.handleInputChange}/>
                    <input type="text" name='episodeTitle' placeholder='Episode Title'
                           onChange={this.handleInputChange}/>

                    <h3>Who Publishes the Podcast?</h3>
                    <input type="text" name='publisher' placeholder='Publisher' onChange={this.handleInputChange}/>

                    <h3>When was the podcast released?</h3>
                    <input type="text" name='dateReleased' placeholder='Date Published'
                           onChange={this.handleInputChange}/>

                    <h3>When did you access the podcast?</h3>
                    <input type="text" name='dateAccessed' placeholder='Date Accessed'
                           onChange={this.handleInputChange}/>

                    <h3>Link to the podcast episode</h3>
                    <input type="text" name='link' placeholder='Podcast link' onChange={this.handleInputChange}/>


          {this.state.firstName &&
          <span>
                    <br/>
                <br/>
                <hr/>
                        <a href="#howtociteapodcastinharvard"><h3>Harvard</h3></a>
                <code>
                    {this.state.lastName}, {firstInitial}. ({this.state.dateReleased}).{this.state.episodeTitle}. [podcast] {this.state.podcastTitle}. Available at: {this.state.link} [{this.state.dateAccessed}]
                </code>
                <br/>

                <a href="#howtociteapodcastinmla8thedition"><h3>MLA</h3></a>
                <code>
                    {this.state.lastName}, {this.state.firstName}. "{this.state.episodeTitle}". {this.state.podcastTitle}, {this.state.publisher}, {this.state.dateReleased}, {this.state.link}.
                </code>
                <br/>


                <a href="#howtociteapodcastinapa"><h3>APA</h3></a>
                <code>
                    {this.state.lastName}, {this.state.firstName} (host). ({this.state.publisher}). ({this.state.dateReleased}). {this.state.episodeTitle} [Audio Podcast] Retrieved from {this.state.link}.
                </code>
                <br/>


                <a href="#howtociteapodcastinchicago"><h3>Chicago</h3></a>
                <code>
                    {this.state.lastName}, {this.state.firstName}. "{this.state.episodeTitle}".{this.state.podcastTitle}. Podcast Audio, {this.state.dateReleased}. {this.state.link}
                </code>
                <br/>
                        </span>
          }
                    </span>
      </div>
        </div>
        </div>
      </div>
    )
  }
}

export default PodcastCite
